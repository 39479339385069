<template>
  <div>
    <v-app>
      <v-card>
        <v-card-title>
          Productos
          <v-spacer></v-spacer>
          <v-btn
            class
            @click="massiveSoftDelete(selected)"
            v-show="selected.length"
            text
            icon
            color="red"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn class @click="$emit('open-carga-masiva-productos-dialog')" text icon color="blue">
            <v-icon style="color: blue !important;">icon-file_upload</v-icon>
          </v-btn>
          <v-btn class href="#/productos/crear" text icon color="blue">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class @click="exportExcel" text icon color="green">
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
            </template>
            <span>Exportar a Excel</span>
          </v-tooltip>
          <v-btn class @click="fetchData" text icon color="blue">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
            <v-card outlined tile elevation="1">
                <v-card-text style="padding-bottom: 0px;">
                    <div class="d-flex justify-space-between">
                        <div class="d-flex flex-column col-10">
                             <v-text-field
                                v-model="search"
                                clearable
                                label="Buscar"
                                hide-details
                                dense
                                solo
                                class="mr-2"
                                @change="fetchData"
                            ></v-text-field>
                        </div>
                        <div class="d-flex flex-column col-2">
                            <v-btn class="btn-starkoms-primary mx-2" dark style="min-width: 138px;" @click="fetchData" >Buscar</v-btn>
                        </div>
                        
                    </div>
                    <div class="d-flex justify-space-between">
                        <div class="d-flex flex-column col-3">
                            <span class="text-h6 font-weight-regular black--text"><v-icon>mdi-border-all</v-icon> Filtro por Categoría</span>
                            <v-select class="ml-2 mr-2" label="Filtro por categoría" 
                            :items="categoriesListFilter" 
                            v-model="selectCategory" 
                            @change="fetchData"
                            solo >
                            </v-select>
                        </div>
                        <div class="d-flex flex-column source_filter col-3">
                            <span class="text-h6 font-weight-regular black--text "><v-icon>mdi-border-outside</v-icon> Filtro por Marca</span>
                             <v-select class="ml-2 mr-2" label="Filtro por Marca" 
                            :items="brandsListFilter" 
                            v-model="selectBrand" 
                            @change="fetchData"
                            solo >
                          </v-select>
                        </div>
                        <div class="d-flex flex-column source_filter col-4">
                        </div>
                        <div class="d-flex flex-column col-2">
                                <v-btn v-show="!!search || !!selectCategory || !!selectBrand" @click="limpiarFiltros" class="mx-2 mt-8" dark color="grey" >Limpiar Filtros</v-btn>
                            </div>
                        
                    </div>
                </v-card-text>
            </v-card>
        </v-card-text>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="tableData"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            :single-select="false"
            :loading="dataTableLoading"
            show-select
            v-model="selected"
            no-results-text="No hay resultados disponibles"
            no-data-text="No hay datos disponibles"
            loading-text="Cargando datos..."
            class="elevation-1 datatable"
            :disable-pagination="true"
            :hide-default-footer="true"
          >
           <template v-slot:item.cover_image="{ item }">
              <td class="text-center">
                <img style=" width:60px;margin:10px;" :src="getCoverImage(item)" /> 
                </td>
            </template>
            <template v-slot:item.is_bundle="{ item }">
              <td class="text-center">
                <button
                  v-if="item.is_bundle"
                  :class="'btn btn-md btn-warning'"
                  v-text="'Bundle'"
                ></button>
                <button
                  v-else
                  :class="'btn btn-md btn-danger'"
                  v-text="'Producto'"
                ></button>
              </td>
            </template>
            <template v-slot:item.active="{ item }">
              <td class="text-center">{{ item.active ? 'Si': 'No' }}</td>
            </template>
            <template v-slot:item.created_at="{ item }">
              <td class="text-center">{{ $moment(item.created_at).format('YYYY-MM-DD hh:mm:ss') }}</td>
            </template>
            <template v-slot:item.acciones="{ item }">
              <td class="text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class @click="$emit('openDialogEditarProducto',item, brandsList, categoriesList)" text icon color="green" >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class @click="softDelete(item)" text icon color="red" >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Borrar</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
          <div class="d-flex justify-space-between mt-4">
            <v-select
              style="max-width: 120px"
              @input="fetchData"
              v-model="selectItemsPerPage"
              label="Registros por página"
              :items="[5,10,20,50,100, 500]"
            ></v-select>
            <v-pagination
              @input="fetchData"
              v-model="page"
              :length="totalPages"
              :total-visible="100"
              color="#e55d43"
            ></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </v-app>
    <dialog-editar-producto @success="fetchData"></dialog-editar-producto>
    <carga-masiva-productos></carga-masiva-productos>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import DialogEditarProducto from "@/views/oms/Productos/DialogEditarProducto.vue";
import CargaMasivaProductos from '@/views/oms/Productos/CargaMasivaProductos.vue';
import EventBus from "@/event-bus";
export default {
  components: {
    DialogEditarProducto,
    CargaMasivaProductos
  },
  data() {
    return {
      dataTableLoading : false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: "",
      selected: [],
      totalPages: 1,
      selectItemsPerPage: 10,
      categoriesList: [],
      brandsList: [],
      categoriesListFilter: [],
      brandsListFilter: [],
      prodsDeleted: 0,
      headers: [
        { text: "", value: "cover_image" },
        { text: "Nombre producto", value: "name" },
        { text: "SKU", value: "sku" },
        { text: "EAN", value: "ean" },
        { text: "Precio", value: "price" },
        { text: "Producto/Bundle", value: "is_bundle" },
        { text: "Activo", value: "active" },
        { text: "Categoría", value: "category.name" },
        { text: "Marca", value: "brand.name" },
        { text: "Fecha creación", value: "created_at" },
        { text: "Acciones", value: "acciones", sortable: false },
      ],
      tableData: [],
      dateMsg:
        new Date().toISOString().substr(0, 10) +
        " " +
        new Date().toISOString().substr(11, 5),
        selectCategory: null,
        selectBrand: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard Productos", route: "productos" },
    ]);
    this.fetchData();
    this.fetchCategories();
    this.fetchBrands();
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      //get the store, created Product alert from crearProducto component
      if (localStorage.getItem("crearProducto") !== null) {
        this.createdProductMessage();
      }
      //get the store, created Bundle alert from crearProducto component
      if (localStorage.getItem("crearBundle") !== null) {
        this.createdBundleMessage();
      }
    },
    fetchData() {
      var vm = this;
      const sortBy = "id";
      const sortDesc = true;
      const page = this.page;
      const itemsPerPage = this.selectItemsPerPage;
      const search = this.search;
      const selectCategory = this.selectCategory;
      const selectBrand = this.selectBrand;
      this.dataTableLoading = true;
      this.axios({
        url: "inventory/products/paginated",
        method: "GET",
        params: {
          page,
          perPage: itemsPerPage,
          sortBy: sortBy,
          sortDesc: sortDesc ? 1 : 0,
          search,
          selectCategory,
          selectBrand
        },
      })
        .then((response) => {
          /**Pagination */
          // vm.pageCount = Math.ceil(response.data.length / 10);
          vm.tableData = response.data.data;
          vm.totalPages = response.data.last_page;
          vm.dataTableLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportExcel(){
      var vm = this;
      this.axios({
        url: 'inventory/products/export/excel',
        method: 'POST',
        responseType: "blob",
      }).then( response => {
        const newBlob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });
        const data = window.URL.createObjectURL(newBlob);
        window.open(data, "_blank");
      }).catch( error => {
        console.log(error);
      });
    },
    openDialogEditar() {
      console.log("openDialogEditar");
      if (!this.selected[0]) return;
      console.log("kkkeke");
      EventBus.$emit("openDialogEditarProducto", this.selected[0]);
    },
    async massiveSoftDelete(items) {
      var vm = this;
      this.prodsDeleted = 0;

      if (confirm("Está seguro que desea borrar '"+(items.length)+"' productos?")) {
        for await (const item of items) {
          await this.doMassiveSoftDelete(item);
        }

        vm.abrirMensajeSuccess(this.prodsDeleted+" productos borrados correctamente.");
      }
    },
    async doMassiveSoftDelete(item) {
        var confirmDelete = true;
        await this.axios({
          url: "orders/products/product/isInBundles/" + item.id, method: "GET"
        })
        .then(async (response) => {
          if (response.data == true) {
            if (confirm("El producto '"+item.name+"' está asociado a uno o más bundles. Si borra el producto también se borrarán los bundles (esta acción no puede deshacerse). ¿Confirma que desea continuar?")) {
              confirmDelete = true;
            }else{
              confirmDelete = false;
            }
          }
          if (confirmDelete) {
            await this.axios({
              url: "orders/products/product/" + item.id, method: "DELETE"
            })
            .then((response) => {
              this.prodsDeleted++;
            })
            .catch((error) => {
              console.log(error);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    softDelete(item) {
      var vm = this;
      var confirmDelete = true;

      if (confirm("Está seguro que desea borrar el producto '"+item.name+"'?")) {
        this.axios({
          url: "orders/products/product/isInBundles/" + item.id,
          method: "GET",
        })
        .then((response) => {
          if (response.data == true) {
            if (confirm("El producto está asociado a uno o más bundles. Si borra el producto también se borrarán los bundles (esta acción no puede deshacerse). ¿Confirma que desea continuar?")) {
              confirmDelete = true;
            }else{
              confirmDelete = false;
            }
          }
          if (confirmDelete) {
            this.axios({
              url: "orders/products/product/" + item.id,
              method: "DELETE",
            })
            .then((response) => {
              vm.abrirMensajeSuccess("Producto borrado correctamente");
            })
            .catch((error) => {
              console.log(error);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
    createdProductMessage() {
      let vm = this;
      let crearProducto = JSON.parse(localStorage.getItem("crearProducto"));

      this.$bvToast.toast(
        `El Producto: ${crearProducto.name} ha sido creado, el ${vm.dateMsg} exitosamente!`,
        {
          title: `Información`,
          variant: "success",
          solid: true,
          toaster: "b-toaster-bottom-center",
        }
      );
      localStorage.removeItem("crearProducto");
    },
    createdBundleMessage() {
      let vm = this;
      let crearBundle = JSON.parse(localStorage.getItem("crearBundle"));

      this.$bvToast.toast(
        `El Bundle: ${crearBundle.name} ha sido creado, el ${vm.dateMsg} exitosamente!`,
        {
          title: `Información`,
          variant: "success",
          solid: true,
          toaster: "b-toaster-bottom-center",
        }
      );
      localStorage.removeItem("crearBundle");
    },
    abrirMensajeSuccess(mensaje) {
      this.$bvToast.toast(mensaje, {
        title: `Información`,
        variant: "success",
        solid: true,
        toaster: "b-toaster-bottom-center",
      });
      this.fetchData();
    },
    fetchCategories(){
        this.categoriesList = [];
        var vm = this;
        this.axios({
            url: "inventory/categories_data_list",
            method: "GET",
        })
        .then((response) => {
            vm.categoriesList = response.data;
            vm.categoriesListFilter.push({text: 'Todo', value: null});
            vm.categoriesListFilter.push(...vm.categoriesList);
        })
        .catch((error) => {
            console.log(error);
        });
    },
    fetchBrands(){
        this.brandsList = [];
        var vm = this;
        this.axios({
            url: "/inventory/brands_list",
            method: "GET",
        })
            .then((response) => {
            vm.brandsList = response.data;
            vm.brandsListFilter.push({text: 'Todo', value: null});
            vm.brandsListFilter.push(...vm.brandsList);
            })
            .catch((error) => {
            console.log(error);
            });
    },
    limpiarFiltros() {
          this.search = "";
          this.selectCategory = null;
          this.selectBrand = null;
          this.fetchData();
      },
      getCoverImage(item) {
          // console.log(item);
          if (item.cover_image  == null) {
              return "https://s3.us-west-1.amazonaws.com/fullkom.oms/starkoms_placeholder.png";
          }
          else{
              console.log(JSON.parse(item.cover_image));
              console.log(JSON.parse(item.cover_image).image_250);
              return JSON.parse(item.cover_image).image_250;
          }

        // var images = require.context('../assets/', false, /\.png$/)
        // return images('./' + pet + ".png")
      }
  },
};
</script>
<style lang="scss">
.v-btn__content{
  .icon-file_upload::before{
    color: #2196F3 !important
  }
}
</style>